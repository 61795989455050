<template>
  
  
  <div id="app">
    
    <HeaderSection />
    <NavigationMenu />
    <router-view />
    <FooterSection />
    
  </div>
</template>

<script>
import HeaderSection from './components/HeaderSection.vue';
import NavigationMenu from './components/NavigationMenu.vue';
import FooterSection from './components/FooterSection.vue';

export default {
  components: {
    HeaderSection,
    NavigationMenu,
    FooterSection
  }
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
  border: none;
}

html {
  background-color: #F8F8F8; /* 你想要的颜色 */
}

#app {
  font-family: Arial, sans-serif;
  max-width: 1410px;
  margin: 0 auto;
}


/* 适配手机端 */
@media screen and (max-width: 1410px) {
  #app {
  font-family: Arial, sans-serif;
  width: 1410px;
  margin: 0 auto;
}
}
</style>
