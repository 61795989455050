<template>
    <div style="padding: 10px 40px 0 40px; background-color: #fff;width:1330px">
      <MyBread :title="title" />
      <div class="layout">
        <div class="left-nav">
          <LeftNav :title="title" @changeList="changeList" @updateTitle="updateTitle"/>
        </div>
        <div class="right-content">
          <el-row class="notice-list">
            <el-col
              v-for="(item, index) in notices"
              :key="index"
              class="notice-item"
            >
              <div class="notice-content">
                <span class="notice-span"></span>
                <p style="margin-left: 8px;">
                  <router-link
                    style="color: inherit; text-decoration: none;"
                    :to="{ name: 'ArticleDetail', params: { id: item.aid, title: title, cid: articleCateId } }"
                  >
                    {{ item.title }}
                  </router-link>
                </p>
                <span class="notice-date">{{ item.create_time }}</span>
              </div>
            </el-col>
          </el-row>
          <el-pagination
            v-if="totalNotices > 0"
            style="margin-top: 20px;"
            background
            layout="prev, pager, next, sizes, jumper"
            :current-page="currentPage"
            :page-size="pageSize"
            :total="totalNotices"
            @current-change="handlePageChange"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { get } from "@/api/request.js";
  import LeftNav from "./LeftNav.vue";
  import MyBread from "./MyBread.vue";
  
  export default {
    components: { LeftNav, MyBread },
    data() {
      return {
        notices: [],
        currentPage: 1,
        pageSize: 20,
        totalNotices: 0,
        articleCateId: "",
        title: "",
        keyword: "",
      };
    },
    created() {
      this.articleCateId = this.$route.params.id;
      this.title = this.$route.params.title;
      this.keyword = this.$route.params.key;
      this.getArticles(this.currentPage, this.pageSize);
    },
    watch: {
      "$route.params.id"(newId) {
        this.articleCateId = newId;
        this.keyword = "";
        this.getArticles(this.currentPage, this.pageSize);
      },
      "$route.params.key"(newKey) {
        this.keyword = newKey;
        this.articleCateId = "";
        this.getArticles(this.currentPage, this.pageSize);
      },
      "$route.params.title"(newTitle) {
        this.title = newTitle; // 更新 title
      }
    },
    methods: {
        changeList(id) {
            console.log(id)
            this.articleCateId = id
            this.getArticles(1,this.pageSize);
        },
        updateTitle(newTitle) {
          this.title = newTitle; // 通过事件更新 title
      },
      getArticles(page, size) {
        const params = { list_rows: size, page };
        if (this.articleCateId) params.cate_id = this.articleCateId;
        if (this.keyword) params.keyword = this.keyword;
  
        get("/api/m8480/67356b919bb18", params)
          .then((response) => {
            if (response.code == 1) {
              this.notices = response.data.data;
              this.totalNotices = response.data.total;
            }
          })
          .catch((error) => console.error("数据获取失败：", error));
      },
      handlePageChange(page) {
        this.currentPage = page;
        this.getArticles(this.currentPage, this.pageSize);
      },
    },
  };
  </script>
  
  <style scoped>
  /deep/ .el-menu{
      border-right:none !important;
  }
  .notice-item:hover .notice-content p {
    color: #409eff; /* 悬停时文字颜色 */
  }
  
  .notice-item:hover .notice-content .notice-date {
    color: #409eff; /* 悬停时日期文字颜色 */
  }
  .notice-span{
  margin-left: 40px;
    width: 6px;
    height: 6px;
    background-color: #0092E5;
  }
  .notice-list {
    /* max-height: 260px; */
    /* overflow-y: auto; */
    margin-top: 22px;
  }
  .notice-item {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    /* border-bottom: 1px solid #e4e7ed; */
  }
  
  .notice-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
  }
  
  
  .notice-content p {
    font-size: 14px;
    color: #333;
    margin: 0;
    flex: 1;
  }
  
  .notice-date {
    font-size: 12px;
    color: #666;
    white-space: nowrap;
  }
  /* 整体布局：左右分栏 */
  .layout {
      
      display: flex;
  }
  
  /* 左侧导航栏样式 */
  .left-nav {
      /* flex: 0 0 250px; */
      width: 250px;
      /* 固定宽度 */
      background-color: #004c9a;
      color: #fff;
  }
  
  /* 右侧内容样式 */
  .right-content {
      height: 1000px;
      flex: 1;
      /* 自动占用剩余空间 */
      padding: 20px;
      background-color: #fff;
  }
  </style>
  