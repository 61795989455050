<template>
  <el-row class="notice-section">
    <!-- 左侧轮播图展示区 -->
    <!-- <div class="left-banner">
      <el-carousel trigger="click" height="320px" indicator-position="bottom">
        <el-carousel-item v-for="image in images" :key="image.alt">
          <img :src="image.src" :alt="image.alt" class="banner-image" />
          <div class="banner-caption">{{ image.caption }}</div>
        </el-carousel-item>
      </el-carousel>
    </div> -->

    <!-- 右侧通知列表区 -->
    <div class="right-notice">
      <div class="notice-header">
        <div class="notice-title">政策</div>
        <div class="more">
          <span style="color:#999999" @click="getmore('政策', 20)">更多</span>
          <img style="width:16px;height: 16px;" src="@/assets/more.png" alt="">
        </div>
      </div>
      <div class="line-box">
        <el-divider class="line"></el-divider>
        <el-divider class="line-two"></el-divider>
      </div>


      <el-row class="notice-list">
        <el-col v-for="(item, index) in notices" :key="index" class="notice-item">
          <div class="notice-content">
            <span class="notice-span"></span>

            <p style="margin-left: 8px;"> <router-link style="color: inherit;text-decoration: none;"
                :to="{ name: 'ArticleDetail', params: { id: item.aid, title: item.operationarticlecate_name, cid: item.operationarticlecate_id } }">{{
                item.title }}</router-link></p>

          </div>
        </el-col>
      </el-row>

    </div>
    <div class="right-notice">
      <div class="notice-header">
        <div class="notice-title">智库</div>
        <div class="more">
          <span style="color:#999999" @click="getmore('智库', 21)">更多</span>
          <img style="width:16px;height: 16px;" src="@/assets/more.png" alt="">
        </div>
      </div>
      <div class="line-box">
        <el-divider class="line"></el-divider>
        <el-divider class="line-two"></el-divider>
      </div>


      <el-row class="notice-list">
        <el-col v-for="(item, index) in notices_zhiku" :key="index" class="notice-item">
          <div class="notice-content">
            <span class="notice-span"></span>
            <p style="margin-left: 8px;"> <router-link style="color: inherit;text-decoration: none;"
                :to="{ name: 'ArticleDetail', params: { id: item.aid, title: item.operationarticlecate_name, cid: item.operationarticlecate_id } }">{{
                item.title }}</router-link></p>

          </div>
        </el-col>
      </el-row>

    </div>


  </el-row>







</template>

<script>
import { get } from '@/api/request.js';
export default {
  data() {
    return {
      images: [
        { src: require('@/assets/banner.png'), alt: 'Banner 1', caption: '中国通用航空与低空经济研究院 - 图1' },
        { src: require('@/assets/banner.png'), alt: 'Banner 2', caption: '中国通用航空与低空经济研究院 - 图2' },
        { src: require('@/assets/banner.png'), alt: 'Banner 3', caption: '中国通用航空与低空经济研究院 - 图3' }
      ],
      notices: [],
      notices_zhiku: []
    }
  },
  created() {

    this.getNotices();
  },
  methods: {
    getmore(title, cid) {
      console.log(111);
      this.$router.replace({ name: 'ArticleList', params: { id: cid, title: title } });
    },
    getNotices() {
      // 政策
      get('/api/m8480/67356b919bb18', { cate_id: '20', list_rows: '5' ,is_show:1}) // 仅填写路径
        .then(response => {
          console.log('文章', response)
          if (response.code == 1) {
            this.notices = response.data.data;
          }
        })
        .catch(error => {
          console.error('数据获取失败：', error);
        });

      // 智库
      get('/api/m8480/67356b919bb18', { cate_id: '21', list_rows: '5' ,is_show:1}) // 仅填写路径
        .then(response => {
          console.log('文章', response)
          if (response.code == 1) {
            this.notices_zhiku = response.data.data;
          }
        })
        .catch(error => {
          console.error('数据获取失败：', error);
        });
    }
  }
}
</script>

<style scoped>
.notice-item:hover .notice-content p {
  color: #409eff;
  /* 悬停时文字颜色 */
}

.notice-item:hover .notice-content .notice-date {
  color: #409eff;
  /* 悬停时日期文字颜色 */
}

.notice-span {
  width: 6px;
  height: 6px;
  background-color: #0092E5;
}

.line-box {
  height: 9px;
  display: flex;
}

.line {
  background-color: #004498;
  width: 56px;
  height: 4px;
  border-radius: 64px 64px 64px 64px
}

.line-two {
  background-color: #EEEEEE;

  height: 4px;
  border-radius: 64px 64px 64px 64px
}

.notice-section {
  display: flex;
  background-color: white;
  /* padding: 20px; */
  /* border: 1px solid #e4e7ed; */
}

.left-banner {
  width: 482px;
  height: 320px;
  margin-left: 40px;
  margin-top: 40px;
}



.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.banner-image {
  width: 482px;
  height: 268px;
}

.banner-caption {
  background-color: #e0e9f3;
  width: 100%;
  height: 52px;
  margin-top: -3px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}



.right-notice {
  width: 634px;
  padding-left: 20px;
  padding-top: 40px;
  margin-left: 25px;
}

.notice-header {
  display: flex;
  justify-content: space-between;


}

.notice-title {
  color: #0052A6;
  font-weight: bold;
  font-size: 20px;
}

.more {
  display: flex;
  align-items: center;
  color: #333;
  cursor: pointer;
}

.more span {
  margin-right: 5px;
  font-size: 14px;
}

.notice-list {
  max-height: 260px;
  overflow-y: auto;
  margin-top: 22px;
}

.notice-item {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  /* border-bottom: 1px solid #e4e7ed; */
}

.notice-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 816px;
}

.notice-content p {
  font-size: 14px;
  color: #333;
  margin: 0;
  flex: 1;
  white-space: nowrap;  /* 禁止换行 */
  overflow: hidden;     /* 隐藏溢出的内容 */
  text-overflow: ellipsis; /* 超出部分显示为省略号 */
  width: 634px;
}

.notice-date {
  font-size: 12px;
  color: #666;
  white-space: nowrap;
}

/* 修改轮播图指示器为圆点 */
/deep/ .el-carousel__indicators {
  bottom: 52px !important;
  /* 控制圆点距离底部的位置 */
  display: flex !important;
  justify-content: center !important;
  /* 中央对齐 */
}

/deep/ .el-carousel__button {
  width: 10px !important;
  /* 圆点的宽度 */
  height: 10px !important;
  /* 圆点的高度 */
  margin: 0 4px !important;
  /* 圆点间距 */
  border-radius: 50% !important;
  /* 圆形 */
  background-color: #FFFFFF !important;
  /* 默认圆点颜色 */
  border: 2px solid #004498;
  opacity: 1 !important;
  /* 默认圆点透明度 */

}

/deep/ .is-active button {

  width: 10px;
  height: 10px;
  background-color: #004498 !important;
  /* 默认圆点颜色 */

  border-radius: 50% !important;
  /* 圆形 */
  opacity: 1 !important;
  /* 默认圆点透明度 */
}




/deep/ .el-carousel__indicators--horizontal {
  /* bottom: 48px; */
}
</style>
