import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import router from './router';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.config.productionTip = false


// 全局路由后置钩子，设置页面标题
router.afterEach((to) => {
  // 设置标题为 meta 中的 title 值，如果没有，设置默认标题
  document.title = to.meta.title || "中国通用航空与低空经济研究院";
});
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
