<template>
  <el-row class="notice-ads">
    
    <div class="top-line">

      <div class="notice-header">
        <div class="notice-title">一带一路</div>
        <div class="more">
          <span style="color:#999999" @click="getmore('一带一路',26)">更多</span>
          <img style="width:16px;height: 16px;" src="@/assets/more.png" alt="">
        </div>
      </div>
      <div class="line-box">
        <el-divider class="line"></el-divider>
        <el-divider class="line-two"></el-divider>
      </div>

      

    </div>
    <div class="logos" >
        <div class="logos-item" v-for="item in notices" :key="item.index">
          <img @click="goto(item.aid)" class="ads" :src="item.img_url" alt="">
        </div>
        
    </div>
  </el-row>

  
  

  
  

  
  
</template>

<script>
import { get } from '@/api/request.js';
export default {
  
  data() {
    return {
      images: [
        { src: require('@/assets/banner.png'), alt: 'Banner 1', caption: '中国通用航空与低空经济研究院 - 图1' },
        { src: require('@/assets/banner.png'), alt: 'Banner 2', caption: '中国通用航空与低空经济研究院 - 图2' },
        { src: require('@/assets/banner.png'), alt: 'Banner 3', caption: '中国通用航空与低空经济研究院 - 图3' },
        { src: require('@/assets/banner.png'), alt: 'Banner 3', caption: '中国通用航空与低空经济研究院 - 图3' },
        { src: require('@/assets/banner.png'), alt: 'Banner 3', caption: '中国通用航空与低空经济研究院 - 图3' },
        { src: require('@/assets/banner.png'), alt: 'Banner 3', caption: '中国通用航空与低空经济研究院 - 图3' },
        { src: require('@/assets/banner.png'), alt: 'Banner 3', caption: '中国通用航空与低空经济研究院 - 图3' },
        { src: require('@/assets/banner.png'), alt: 'Banner 3', caption: '中国通用航空与低空经济研究院 - 图3' },
        { src: require('@/assets/banner.png'), alt: 'Banner 3', caption: '中国通用航空与低空经济研究院 - 图3' },
        { src: require('@/assets/banner.png'), alt: 'Banner 3', caption: '中国通用航空与低空经济研究院 - 图3' },
        { src: require('@/assets/banner.png'), alt: 'Banner 3', caption: '中国通用航空与低空经济研究院 - 图3' },
        { src: require('@/assets/banner.png'), alt: 'Banner 3', caption: '中国通用航空与低空经济研究院 - 图3' },
        { src: require('@/assets/banner.png'), alt: 'Banner 3', caption: '中国通用航空与低空经济研究院 - 图3' },
        { src: require('@/assets/banner.png'), alt: 'Banner 3', caption: '中国通用航空与低空经济研究院 - 图3' },
        { src: require('@/assets/banner.png'), alt: 'Banner 3', caption: '中国通用航空与低空经济研究院 - 图3' }

      ],
      notices:[]

      
    }
    
  },
  created(){
    this.getNotices()
  },
  methods:{
    goto(aid){
      this.$router.replace({ name: 'ArticleDetail', params: { id: aid ,title:'一带一路',cid:'26'} });
    },
    getmore(title,cid){
      console.log(111);
      this.$router.replace({ name: 'ArticleList', params: { id: cid,title: title} });
    },
    getNotices() {
      // 政策
      get('/api/m8480/67356b919bb18', { cate_id: '26', list_rows: '15' }) // 仅填写路径
        .then(response => {
          console.log('文章', response)
          if (response.code == 1) {
            this.notices = response.data.data;
          }
        })
        .catch(error => {
          console.error('数据获取失败：', error);
        });

    }
  }
}
</script>

<style scoped>
.ads{
  width: 242px;
  height: 136px;
}
.logos{
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  
  width: 1330px;
  /* background-color: red; */
}

.logos-item{
  margin-right: 30px;
  
  margin-bottom: 26px;
}

.logos-item:nth-child(5n){
    margin-right: 0px;
}

.notice-ads{
  padding: 40px;
  background-color: #fff;
  width: 1410px;
}
.notice-span{
  width: 6px;
  height: 6px;
  background-color: #0092E5;
}
.line-box{
  height: 9px;
  display: flex;
}
.line{
  background-color: #004498;
  width: 96px;
  height: 4px;
  border-radius: 64px 64px 64px 64px
}

.line-two{
  background-color: #EEEEEE;
  
  height: 4px;
  border-radius: 64px 64px 64px 64px
}

.notice-section {
  display: flex;
  background-color: white;
  /* padding: 20px; */
  /* border: 1px solid #e4e7ed; */
}

.left-banner {
  width: 482px;
  height: 320px;
  margin-left: 40px;
  margin-top: 40px;
}



.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.banner-image {
  width: 482px;
  height: 268px;
}

.banner-caption {
  background-color: #e0e9f3;
  width: 100%;
  height: 52px;
  margin-top: -3px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}



.right-notice {
  width: 816px;
  padding-left: 20px;
  padding-top: 40px;
}

.notice-header {
  display: flex;
  justify-content: space-between;
  
  
}

.notice-title {
  color: #0052A6;
  font-weight: bold;
  font-size: 20px;
}

.more {
  display: flex;
  align-items: center;
  color: #333;
  cursor: pointer;
}

.more span {
  margin-right: 5px;
  font-size: 14px;
}

.notice-list {
  max-height: 260px;
  overflow-y: auto;
  margin-top: 22px;
}
.notice-item {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  /* border-bottom: 1px solid #e4e7ed; */
}

.notice-content{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:816px;
}
.notice-content p {
  font-size: 14px;
  color: #333;
  margin: 0;
  flex: 1;
}

.notice-date {
  font-size: 12px;
  color: #666;
  white-space: nowrap;
}

</style>
