<template>
  <el-row class="left-nav-container">
    <el-col :span="24">
      <h5></h5>
      <el-menu
        class="el-menu-vertical-demo"
        background-color="#004c9a"
        text-color="#fff"
        active-text-color="#fff"
        :default-openeds="['1']"
        unique-opened
      >
        <!-- 一级菜单 -->
        <el-submenu index="1" class="submenu-expanded">
          <template #title>
            <span>{{ title }}</span>
          </template>
          <el-menu-item-group>
            <!-- 二级菜单 -->
            <el-menu-item
              v-for="(item, index) in itemData"
              :key="index"
              :index="`1-${index}`"
            >
              <i class="el-icon-arrow-right"></i>
              <span @click="changeList()">{{ item.name }}</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </el-col>
  </el-row>
</template>

<script>
import { get } from "@/api/request.js";
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cid: 0,
      itemData: [],
    };
  },
  created() {
    this.cid = this.$route.params.cid;
    this.getCate(this.cid);
  },
  watch: {
    "$route.params.id"(newId) {
      this.cid = newId;
      this.getCate(this.cid);
    },
  },
  methods: {
    changeList() {
      this.$router.replace({ name: "ArticleList", params: { id: this.cid, title: this.title } });
    },
    getCate(cid) {
      get("/api/m8480/673d4218742b3", { pid: cid }) // 仅填写路径
        .then((response) => {
          if (response.code == 1) {
            this.itemData = response.data;
          }
        })
        .catch((error) => {
          console.error("数据获取失败：", error);
        });
    },
  },
};
</script>

<style scoped>
/* 禁用滚动条 */
::v-deep(.el-menu-vertical-demo) {
  overflow: hidden !important; /* 禁用滚动条 */
  height: 100%; /* 让菜单填满父容器 */
}

/* 隐藏滚动条样式 */
::v-deep(.el-menu-vertical-demo::-webkit-scrollbar) {
  display: none; /* 隐藏滚动条 */
}

/* 一级菜单默认展开且不可折叠 */
::v-deep(.submenu-expanded > .el-submenu__title) {
  pointer-events: none !important; /* 禁用点击行为 */
}

/* 隐藏一级菜单的折叠图标 */
::v-deep(.submenu-expanded > .el-submenu__title > i) {
  display: none !important;
}

/* 子菜单标题样式 */
::v-deep(.el-submenu__title) {
  background-color: #3378B9 !important;
  text-align: center !important;
  font-size: 24px !important;
}

/* 菜单项样式 */
::v-deep(.el-menu-item) {
  font-size: 16px !important;
  border-bottom: 1px solid #003d78 !important;
  display: flex !important;
  align-items: center !important; /* 垂直居中 */
}

/* 菜单图标样式 */
::v-deep(.el-icon-arrow-right) {
  font-size: 15px !important;
  color: #fff;
}
</style>
