<template>
    <div class="crumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
            
        </el-breadcrumb>
    </div>

</template>
<script>
export default {
    props: {
    title: {
      type: String,
      required: true
    }
  },
}
</script>
<style>
    .crumb{
        height: 30px;
        display: flex;
        align-items: center;
        background-color: #fff;
    }
    
    /deep/ .{
        color:black !important;
    }
</style>