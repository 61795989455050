<template>
  <div>
    <MainContentSection />
    <ExhibitionSection />
    <AdsImage :src="image.thumb" :href="image.href" :title="image.param.name" :id="image.param.id"/>
    <MidContentSection />
    <AdsImage :src="image1.thumb" :href="image1.href" :title="image1.param.name" :id="image1.param.id" />
    <AllianceSection />
  </div>

  <!-- 添加其他首页内容组件 -->

</template>

<script>
import AllianceSection from './AllianceSection.vue';
import MainContentSection from './MainContentSection.vue';
import ExhibitionSection from './ExhibitionSection.vue';
import MidContentSection from './MidContentSection.vue'
import AdsImage from './AdsImage.vue';
import { get } from '@/api/request.js';
export default {
  components: {
    AllianceSection,
    MainContentSection,
    ExhibitionSection,
    AdsImage,
    MidContentSection
    // 其他组件
  },
  data() {
    return {
      image: { thumb: '' ,href:'',param:{name:'',id:''}},
      image1:  { thumb: '' ,href:'',param:{name:'',id:''}},

    }
  },
  created() {
    this.getBanner();
    this.getBanner2();
    // this.getNotices();
  },
  methods: {
    getBanner() {
      // 广告图1
      get('/api/m8480/641e624160dd0', { type: '18', sort_field: 'sort', sort_type: 'desc' }) // 仅填写路径
        .then(response => {
          console.log(response)
          if (response.code == 1) {
            this.image = response.data[0];

          }
        })
        .catch(error => {
          console.error('数据获取失败：', error);
        });

    },
    getBanner2() {
      // 广告图2
      get('/api/m8480/641e624160dd0', { type: '19', sort_field: 'sort', sort_type: 'desc' }) // 仅填写路径
        .then(response => {
          console.log(response)
          if (response.code == 1) {
            this.image1 = response.data[0];

          }
        })
        .catch(error => {
          console.error('数据获取失败：', error);
        });
    }
  }
};
</script>