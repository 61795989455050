<template>
  
  <header class="header" :style="{ backgroundImage: `url(${image})` }">
    <div class="header-content">
      <!-- <img class="logo" src="@/assets/logo.png" alt="Logo" />
      <div class="header-text">
        <div class="title">{{ title }}</div>
        <div class="title_h2">{{ title_h2 }}</div>
      </div> -->
    </div>
    <div class="search-container">
      <el-input
        v-model="searchText"
        placeholder="请输入搜索内容"
        class="search-input"
        size="medium"
      >
        <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
      </el-input>
    </div>
  </header>
</template>

<script>
import { get } from "@/api/request.js";

export default {
  data() {
    return {
      searchText: "",
      image: "", // 背景图片链接
      title: "", // 系统配置参数
      title_h2: "", // 系统配置参数
    };
  },
  created() {
    this.getHeaderImg();
    this.getHeaderData();
  },
  methods: {
    handleSearch() {
      const targetRoute = {
        name: "ArticleList",
        params: {
          id: "0", // 重置分类为默认
          title: "搜索",
          key: this.searchText || "", // 将关键词传递到路由参数
        },
      };

      // 如果当前路由相同，触发刷新
      if (
        this.$route.name === targetRoute.name &&
        this.$route.params.id === targetRoute.params.id &&
        this.$route.params.title === targetRoute.params.title &&
        this.$route.params.key === targetRoute.params.key
      ) {
        this.$emit("refreshData", targetRoute.params);
        return;
      }

      // 路由跳转
      this.$router.push(targetRoute);
    },
    getHeaderImg() {
      get("/api/m8480/641e624160dd0", {
        type: "20",
        sort_field: "sort",
        sort_type: "desc",
      })
        .then((response) => {
          if (response.code == 1 && response.data.length > 0) {
            this.image = response.data[0].thumb; // 设置背景图片
          }
        })
        .catch((error) => console.error("数据获取失败：", error));
    },
    getHeaderData() {
      get("/api/m8480/64534cbb2c352", { code: "web_site_title" })
        .then((response) => {
          if (response.code == 1 && response.data.length > 0) {
            this.title = response.data;
          }
        })
        .catch((error) => console.error("数据获取失败：", error));

      get("/api/m8480/64534cbb2c352", { code: "web_h2_info" })
        .then((response) => {
          if (response.code == 1 && response.data.length > 0) {
            this.title_h2 = response.data;
          }
        })
        .catch((error) => console.error("数据获取失败：", error));
    },
  },
};
</script>

<style scoped>
.header-text{
  font-family: 'Noto Serif SC', serif; /* 思源宋体（简体中文） */
  font-weight: 900; /* 设置为加粗 */
  
 
}
.header {
  color: white;
  padding: 20px;
  text-align: center;
  height: 316px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 0.94;
  display: flex;
  align-items: center;
  position: relative;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 148px;
  margin: 0 34px;
}

.title {
  font-weight: 700;
  font-size: 40px;
  color: #ffffff;
}

.title_h2 {
  font-weight: 400;
  font-size: 15px;
  height: 25px;
  line-height: 32px;
}

.search-container {
  position: absolute;
  bottom: 3px;
  right: 5px;
}

.search-input {
  width: 300px;
}
.search-input :deep(.el-input__inner) {
  border-radius: 4px;
}
.search-input :deep(.el-input-group__append) {
  background-color: #0052a6;
  border-color: #0052a6;
  color: #fff;
}
.search-input :deep(.el-input-group__append:hover) {
  background-color: #3378b9;
}
</style>
