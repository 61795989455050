<template>
  <el-row class="notice-section">

    <!-- 左侧轮播图展示区 -->
    <div>
      <div class="top-line">

        <div class="notice-header">
          <div class="notice-title">联盟</div>
          <div class="more" @click="getmore('联盟', 22)">
            <span style="color:#999999">更多</span>
            <img style="width:16px;height: 16px;" src="@/assets/more.png" alt="">
          </div>
        </div>
        <div class="line-box">
          <el-divider class="line"></el-divider>
          <el-divider class="line-two"></el-divider>
        </div>

      </div>

      <!-- 右侧通知列表区 -->
      <div class="right-notice">

        <div class="left-banner">
          <img :src="img1" alt="">
        </div>
        <el-row class="notice-list">
          <el-col v-for="(item, index) in notices" :key="index" class="notice-item">
            <div class="notice-content">
              <span class="notice-span"></span>
              <p style="margin-left: 8px;"> <router-link style="color: inherit;text-decoration: none;"
                  :to="{ name: 'ArticleDetail', params: { id: item.aid, title: item.operationarticlecate_name, cid: item.operationarticlecate_id } }">{{
                  item.title }}</router-link></p>

            </div>
          </el-col>
        </el-row>
      </div>


    </div>
    <div style="margin-left: 40px;">
      <div class="top-line">

        <div class="notice-header">
          <div class="notice-title">展会</div>
          <div class="more">
            <span style="color:#999999" @click="getmore('展会', 22)">更多</span>
            <img style="width:16px;height: 16px;" src="@/assets/more.png" alt="">
          </div>
        </div>
        <div class="line-box">
          <el-divider class="line"></el-divider>
          <el-divider class="line-two"></el-divider>
        </div>

      </div>

      <!-- 右侧通知列表区 -->
      <div class="right-notice">

        <div class="left-banner">
          <img :src="img2" alt="">
        </div>
        <el-row class="notice-list">
          <el-col v-for="(item, index) in noticestwo" :key="index" class="notice-item">
            <div class="notice-content">
              <span class="notice-span"></span>
              <p style="margin-left: 8px;"> <router-link style="color: inherit;text-decoration: none;"
                  :to="{ name: 'ArticleDetail', params: { id: item.aid, title: item.operationarticlecate_name, cid: item.operationarticlecate_id } }">{{
                  item.title }}</router-link></p>

            </div>
          </el-col>
        </el-row>
      </div>


    </div>

  </el-row>




</template>

<script>
import { get } from '@/api/request.js';
export default {
  data() {
    return {
      notices: [],
      noticestwo: [],
      img1:'',
      img2:''

    }
  },
  created() {

    this.getNotices();
    this.getNoticestwo();
    this.getNoticesImg()
    this.getNoticesImg2()
  },
  methods: {
    getmore(title, cid) {
      console.log(111);
      this.$router.replace({ name: 'ArticleList', params: { id: cid, title: title } });
    },
    getNotices() {
      // 文章
      get('/api/m8480/67356b919bb18', { cate_id: '22', list_rows: '5',is_show:1 }) // 仅填写路径
        .then(response => {
          console.log('文章', response)
          if (response.code == 1) {
            this.notices = response.data.data;
          }
        })
        .catch(error => {
          console.error('数据获取失败：', error);
        });
    },
    getNoticesImg() {
      // 文章
      get('/api/m8480/673d7186bb758', { aid: '22'}) // 仅填写路径
        .then(response => {
          console.log('文章', response)
          this.img1 = response.data.img
        })
        .catch(error => {
          console.error('数据获取失败：', error);
        });
    },
    getNoticesImg2() {
      // 文章
      get('/api/m8480/673d7186bb758', { aid: '23'}) // 仅填写路径
        .then(response => {
          console.log('文章', response)
          this.img2 = response.data.img
        })
        .catch(error => {
          console.error('数据获取失败：', error);
        });
    },

    getNoticestwo() {
      // 文章
      get('/api/m8480/67356b919bb18', { cate_id: '23', list_rows: '5' ,is_show:1}) // 仅填写路径
        .then(response => {
          console.log('文章noticestwo', response)
          if (response.code == 1) {
            this.noticestwo = response.data.data;
          }
        })
        .catch(error => {
          console.error('数据获取失败：', error);
        });
    }
  }
}
</script>

<style scoped>
.notice-item:hover .notice-content p {
  color: #409eff;
  /* 悬停时文字颜色 */
}

.notice-item:hover .notice-content .notice-date {
  color: #409eff;
  /* 悬停时日期文字颜色 */
}

.notice-span {
  width: 6px;
  height: 6px;
  background-color: #0092E5;
}

.line-box {
  height: 9px;
  display: flex;
}

.line {
  background-color: #004498;
  width: 56px;
  height: 4px;
  border-radius: 64px 64px 64px 64px
}

.line-two {
  background-color: #EEEEEE;

  height: 4px;
  border-radius: 64px 64px 64px 64px
}

.notice-section {
  display: flex;
  background-color: white;
  padding: 0px 40px;
  /* border: 1px solid #e4e7ed; */
}

.top-line {
  width: 634px;
  height: 70px;


}

.left-banner img {
  width: 294px;
  height: 204px;
  background-size: cover;
  background-position: center;

}







.right-notice {

  width: 634px;
  display: flex;
  justify-content: space-between;
}

.notice-header {
  display: flex;
  justify-content: space-between;


}

.notice-title {
  color: #0052A6;
  font-weight: bold;
  font-size: 20px;
}

.more {
  display: flex;
  align-items: center;
  color: #333;
  cursor: pointer;
}

.more span {
  margin-right: 5px;
  font-size: 14px;
}

.notice-list {
  max-height: 260px;
  overflow-y: auto;
  height: 204px;

}

.notice-item {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  padding-left: 24px;

  /* border-bottom: 1px solid #e4e7ed; */
}

.notice-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 816px;


}

.notice-content p {
  font-size: 14px;
  color: #333;
  margin: 0;
  width: 300px;
  white-space: nowrap;
  /* 确保文本在一行内显示 */
  overflow: hidden;
  /* 隐藏超出容器的内容 */
  text-overflow: ellipsis;
  /* 使用省略号表示被截断的文本 */

}

.notice-date {
  font-size: 12px;
  color: #666;
  white-space: nowrap;
}
</style>
