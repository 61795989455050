<template>
  <el-footer class="footer">
    <!-- <el-link style="color:#fff;" @click="goto(19)" >加入我们</el-link>
    <span style="color:#95a6b3;margin-left:10px;">|</span> -->
    <el-link style="color:#fff;margin-left:10px;" @click="goto(20)">关于我们</el-link>
  </el-footer>
</template>

<style scoped>
.footer {
  background-color: #004c9a;
  color: white;
  text-align: center;
  font-size: 16px;
  height: 48px !important;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中，如需 */
}
</style>

<script>
export default {
  methods: {
    goto(id) {
      const currentRoute = this.$route;
      // 如果目标路径与当前路径不同，则进行导航
      if (currentRoute.name !== 'ArticleDetailsy' || currentRoute.params.id !== id) {
        this.$router.replace({ name: 'ArticleDetailsy', params: { id: id } });
      }
    },
  },
};
</script>
