// src/router/index.js
import Vue from 'vue';
import VueRouter from 'vue-router';
import BodyInfo from '../components/BodyInfo.vue';
import ArticleDetail from '../components/ArticleDetail.vue';
import ArticleDetailsy from '../components/ArticleDetailsy.vue';
import ArticleList from '../components/ArticleList.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: BodyInfo
  },
  {
    path: '/article/:id/:title/:cid',
    name: 'ArticleDetail',
    component: ArticleDetail,
    props: true // 允许将路由参数作为 props 传递给组件
  },
  {
    path: '/article/:id',
    name: 'ArticleDetailsy',
    component: ArticleDetailsy,
    props: true // 允许将路由参数作为 props 传递给组件
  },
  {
    path: '/articleList/:id/:title/:key?',
    name: 'ArticleList',
    component: ArticleList,
    props: true // 允许将路由参数作为 props 传递给组件
  }
];

const router = new VueRouter({
  mode: 'hash',
  routes
});

export default router;
