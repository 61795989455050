<template>
  
    <div style="background-color: #fff;padding: 0 40px;">
      <h2 class="article-title">{{ article.name }}</h2>
      <p class="article-date">{{ article.create_time }}</p>
      <div class="article-content" v-html="article.content"></div>
    </div>
    
  
</template>

<script>

import { get } from '@/api/request.js';
export default {
  
  name: 'ArticleDetail',
  components: {
   
    },
  data() {
    return {
      article: {},
      articleId:'',
      title:"文章详情"
      
    }
  },
  created() {
     this.articleId = this.$route.params.id;
    
     this.getNotices();
  },
  methods: {
        getNotices(){
          // 文章
          get('/api/m8480/641568f1b6f87', {column_id:this. articleId}) // 仅填写路径
            .then(response => {
                console.log('文章',response)
                if(response.code==1){
                    this.article = response.data;
                }
            })
            .catch(error => {
              console.error('数据获取失败：', error);
            });
        }
    }
}
</script>

<style scoped>
/* 左侧导航栏样式 */
.left-nav {
    flex: 0 0 250px;
    /* 固定宽度 */
    background-color: #004c9a;
    color: #fff;
}
.article-detail {
  display: flex;
  margin: 0 auto;
  /* padding: 20px; */
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.article-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.article-date {
  color: #999;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: center;
}

.article-content {
  font-size: 16px;
  line-height: 1.6;
}
</style>
