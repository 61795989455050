<template>
    <el-row class="left-nav-container">
      <el-col :span="24">
        <el-menu
          class="el-menu-vertical-demo"
          background-color="#004c9a"
          text-color="#fff"
          active-text-color="#fff"
          :default-openeds="['1']"
          unique-opened
        >
          <!-- 一级菜单 -->
          <el-submenu index="1" class="submenu-expanded">
            <template #title>
              <span>{{ title }}</span>
            </template>
            <el-menu-item-group>
              <!-- 二级菜单 -->
              <el-menu-item
                v-for="(item, index) in itemData"
                :key="index"
                :index="`1-${index}`" @click="changeList(item.aid)"
              >
                <i class="el-icon-arrow-right"></i>
                <span >{{ item.name }}</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-col>
    </el-row>
  </template>
  
  <script>
  import { get } from "@/api/request.js";
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        cid: 0,
        itemData: [],
      };
    },
    created() {
      this.cid = this.$route.params.id;
      this.getCate(this.cid);
      console.log(this.title)
    },
    watch: {
      "$route.params.id"(newId) {
        this.cid = newId;
        this.getCate(this.cid);
      },
    },
    methods: {
      changeList(aid,name) {
        this.$emit("changeList", aid);
        console.log(name)
        // this.$emit("updateTitle", name); // 更新 title
      },
      getCate(cid) {
        get("/api/m8480/673d4218742b3", { pid: cid }) // 仅填写路径
          .then((response) => {
            if (response.code == 1) {
              this.itemData = response.data;
            }
          })
          .catch((error) => {
            console.error("数据获取失败：", error);
          });
      },
    },
  };
  </script>
  
  <style scoped>
  /* 禁用滚动条 */
  ::v-deep(.el-menu-vertical-demo) {
    overflow: hidden;
    height: 100%;
  }
  
  ::v-deep(.el-menu-vertical-demo::-webkit-scrollbar) {
    display: none;
  }
  
  /* 一级菜单始终展开 */
  ::v-deep(.submenu-expanded > .el-submenu__title) {
    pointer-events: none; /* 禁用折叠功能 */
  }
  
  /* 隐藏一级菜单折叠图标 */
  ::v-deep(.submenu-expanded > .el-submenu__title > i) {
    display: none;
  }
  
  /* 子菜单标题样式 */
  ::v-deep(.el-submenu__title) {
    background-color: #3378B9 !important;
    text-align: center !important;
    font-size: 24px !important;
  }
  
  /* 菜单项样式 */
  ::v-deep(.el-menu-item) {
    font-size: 16px !important;
    border-bottom: 1px solid #003d78 !important;
    display: flex !important;
    align-items: center !important;
  }
  
  /* 菜单图标样式 */
  ::v-deep(.el-icon-arrow-right) {
    font-size: 15px !important;
    color: #fff;
  }

  ::v-deep(.el-menu-item:hover) {
  background-color: #3378B9  !important; /* 设置鼠标悬浮背景颜色为黄色 */
}


  </style>
  