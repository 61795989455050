// src/api/request.js
import axios from 'axios';
import config from '@/config'; // 引入基础 URL 配置

// 创建 axios 实例
const instance = axios.create({
  baseURL: config.baseURL, // 使用封装的基础 URL
  timeout: 5000 // 请求超时时间
});

// 请求拦截器
instance.interceptors.request.use(
  config => {
    return config;
  },
  error => Promise.reject(error)
);

// 响应拦截器
instance.interceptors.response.use(
  response => response.data, // 简化返回的数据结构
  error => {
    console.error("请求错误：", error);
    return Promise.reject(error);
  }
);

// 封装通用的请求方法
export function get(url, params) {
  return instance.get(url, { params });
}

export function post(url, data) {
  return instance.post(url, data);
}

export function put(url, data) {
  return instance.put(url, data);
}

export function del(url) {
  return instance.delete(url);
}
