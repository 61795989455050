<template>
  <el-row class="notice-ads">
    <img @click="getBannerDetail()" class="ads" :src="src" alt="">
  </el-row>

  
  

  
  

  
  
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    href:{
      type: String,
      required: true
    },
    title:{
      type: String,
      required: true
    }
  },
  data() {
    return {
      
      
    }
  },
  methods:{
    getBannerDetail(){
      console.log(this.id);
      if(this.href){
        this.$router.replace({ name: this.href, params: { id: this.id,title: this.title} });
      }
    }
  }
}
</script>

<style scoped>
  .notice-ads{
    
    background-color:#fff;
    display: flex;
    justify-content: center;
    padding: 30px;
  }
  .ads{
    
    width: 1330px;
    height:160px;
  }

</style>
