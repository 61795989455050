<template>
    <el-menu class="navbar" mode="horizontal" background-color="#0052A6" text-color="#fff" active-text-color="#fff">
        <!-- <router-link to="/">
            <el-menu-item index="1">首页</el-menu-item>
        </router-link> -->
        <div v-for="(item, index) in navData" :key="index.toString()">
            <router-link style="color: inherit;text-decoration: none;" :to="{ name: item.href, params: { id: item.param.id ,title:item.name} }">
                <el-menu-item :key="item.param.id" :index="index.toString()">{{item.name}}</el-menu-item>
            </router-link>
        </div>
        
        
    </el-menu>
</template>
<script>
import { get } from '@/api/request.js';
export default {

    data() {
        return {
            navData:[],
            

        }
    },
    created() {
        this.getNav();
    },
    methods: {
        getNav() {
            // 发送 GET 请求
            get('/api/m8480/641e5f0526964', {sort_field:'sort',sort_type:'desc'}) // 仅填写路径
            .then(response => {
                console.log(response)
                if(response.code==1){
                    this.navData = response.data;
                }
            })
            .catch(error => {
                    console.error('数据获取失败：', error);
            });
        }
    }
}
</script>
<style scoped>
.navbar {
    text-align: center;
    padding: 10px 0;
}

.el-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 39px;
}

.el-menu-item {
    padding: 0 57px;
    border: none !important;
}

.el-menu.el-menu--horizontal {
    border: none;
}

/* 增强选中项的样式优先级 */
.navbar .el-menu-item.is-active {
    background-color: #3378B9 !important;
}

.navbar .el-menu-item:hover {
    background-color: #3378B9 !important;
}

/* 自定义选中项的下划线 */
.navbar .el-menu-item.is-active::after {
    content: "";
    display: block;
    margin: -10px auto;
    width: 24px;
    height: 4px;
    background-color: #fff;
    border-radius: 2px;
}
</style>
